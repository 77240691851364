import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import { graphql, Link } from "gatsby"
import logo from "../assets/images/logo-bluemail.svg"
import visuallyAppealingImg from '../assets/images/features/Visually-Appealing.webp'

export default function StandaloneTemplate(props) {
  const post = props.pageContext
  let postHTML = post.html
  const currentYear = new Date().getFullYear()

  console.log(post)

  return (
    <Layout env={props.data.site.siteMetadata.env}>
          <Navbar />
          <SEO
            postTitle={post.frontmatter.metaTitle + " | BlueMail App"}
            postDescription={post.frontmatter.metaDescription}
            postImage={post.frontmatter.ogImage ? post.frontmatter.ogImage : post.frontmatter.image}
            postURL={`standalone/${post.frontmatter.path}`}
          />
         {post.frontmatter.publishToProd && <div className="standalone-blog-post bg-nav">
              {/* <img src={logo} alt="logo" className="standalone-blog-logo" /> */}
            <div className="container">
              <div className="col-12">
                {post.frontmatter.image && (
                  <img
                    src={post.frontmatter.image}
                    style={{ width: "100%" }}
                    alt={`BlueMail ${post.frontmatter.blogTitle}`}
                  />
                )}
                <div className="blog-content" style={{ marginTop: "45px" }}>
                  <h2>{post.frontmatter.blogTitle}</h2>

                  {/* {moment(post.frontmatter.date).format('ll')} */}

                  <div
                    id="post-html"
                    className="posta"
                    dangerouslySetInnerHTML={{ __html: postHTML }}
                  />
                </div>
              </div>          
            </div>
          </div>
        }
        {!post.frontmatter.publishToProd && <div className="container pt-120 pb-100">
          <div className="not-found-area">
            <div>
                <img style={{maxWidth: "100%"}} src={visuallyAppealingImg} alt="visuallyAppealingImg" />
            </div>
            <div style={{padding: '0 30px'}}>
                <h1 className="mb-20" style={{fontSize: '3rem', fontWeight: 'normal'}}>No blog found!</h1>
                <h1 className="mb-30" style={{fontSize: '2rem', fontWeight: 'normal'}}>Please check Standalone Pages.</h1>
                <Link to='/standalone-blog' className='default-btn'>Back to Standalone Blogs</Link>
            </div>
            
            </div>
          </div>
        }
        <Footer />
    </Layout>
  )
}

export const query = graphql`
  query StandaloneTemplatePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
